Utils = require '../../helpers/Utils'
{ API } = require '../../redux/Store'

{ withTranslation } = require 'react-i18next'

module.exports = withTranslation() createReactClass
  displayName: "CFilterDropdown"

  getInitialState: ->
    if @props.nullable == false
      options = @props.options || []
    else
      options = [null].concat(@props.options || [])

    value = @props.value
    if isNaN(value)
      value = Utils.trimQuotes value

    id: "Dropbox_" + Math.random()
    value: value
    placeholder: @props.placeholder || @props.t("filter.dropdown.none")
    display: "none"
    nullable: !!@props.nullable
    options: options
    values: []

  UNSAFE_componentWillReceiveProps: (newProps) ->
    if(newProps['options'])
      if @props.nullable == false
        options = newProps.options || []
      else
        options = [null].concat(newProps.options || [])
      @setState options: options
    if(newProps['value'])
      @setState value: newProps.value

  onFocus: (e)->
    @props.onFocus?(e)

  onPaste: (e)->
    @props.onPaste?(e)

  onCopy: (e)->
    @props.onCopy?(e)

  onBlur: (e)->
    @props.onBlur?(e)

  componentDidMount: ()->
    document.body.addEventListener "click", (e)=>
      if @state.display == "block" && e.target.closest("a.href-dropdown")?.id != @state.id
        if (!e.target.classList.contains("menu-dropdown"))
          @close()

  close: ()->
    document.body.classList.remove("dropdown-open")
    @setState display: "none"

  open: ()->
    document.body.classList.add("dropdown-open")
    @setState display: "block"

  toggle: (e)->
    e.preventDefault()
    e.stopPropagation()

    open = @state.display == "none"
    if open && @state.options.length == 0 && @props.name
      API.dictionary @props.name, (list)=>
        if list.length > 0
          opts = list.map (e)-> {label: e, value: e}
          if @props.nullable == false
            opts = @props.options || []
          else
            opts = [null].concat(@props.options || [])
          @setState options: opts
    if open
      @open()
    else
      @close()


  select: (value, e)->
    if value?.disabled
      return
    @setState value: value?.value
    @props.onChange?(@props.name, value?.value)

  componentWillReceiveProps: (newProps)->
    if newProps.value != @state.value
      @setState value: newProps.value

  render: ->
    selectedItem = ((@state.options || []).filter (e)=> @state.value == e?.value)[0]
    React.createElement("div", {"className": "box-control-dropdown", "style": (@props.style)},
      React.createElement("div", {"className": "box-dropdown"},
        React.createElement("a", {"className": "href-dropdown", "id": (@state.id), "onClick": (@toggle), "name": (@props.name)},
          React.createElement("div", {"className": "arrow-dropdown"}, React.createElement("div", {"className": "box-icon"}, React.createElement("i", {"className": "fa fa-angle-down"}))),
          React.createElement("div", {"className": "change-value"},
            (if !@state.value
              React.createElement("div", {"className": "form-control not-active"}, (@state.placeholder))
            else
              React.createElement("div", {"className": "form-control"}, (selectedItem?.labelSelected || selectedItem?.label || "#{@state.value}"))
            )
          )
        ),
        React.createElement("div", {"className": "menu-dropdown", "style": (display: @state.display)},
          (@state.options.map (value, i)=>
            React.createElement("a", {"key": (i), "onClick": (@select.bind @, value), "className": (if value?.disabled then "disabled" else "")},
              React.createElement("div", {"className": ("form-control #{if value == null then " not-active" else ""}")},
                (if value == null then @props.t('filter.dropdown.none') else value.label)
              )
            )
          )
        )
      )
    )

import { Chart } from "react-google-charts";
import { useMemo, useCallback, useRef, useState, useEffect } from "react";
import { formatNumber } from "../../helpers/Utils";
import CChartPopup from "./CChartPopup";
import { useTranslation } from "react-i18next";
import { translateGroupValue } from "./ChartUtils";

const baseTextStyle = {
  fontSize: 12,
  bold: false,
  italic: false,
  color: "#3e3e3f",
};

const titleTextStyle = {
  ...baseTextStyle,
  bold: true,
};

const baseOptions = {
  chartArea: {
    height: "85%",
    width: "100%",
    left: 140,
  },
  title: "Column Chart",
  titleTextStyle: {
    color: "transparent",
  },
  hAxis: {
    titleTextStyle: titleTextStyle,
    textStyle: baseTextStyle,
  },
  legend: "none",
  tooltip: {
    trigger: "hover",
  },
  explorer: {
    actions: ["dragToZoom", "rightClickToReset"],
    keepInBounds: true,
    maxZoomIn: 100,
  },
  vAxis: {
    titleTextStyle: { ...titleTextStyle, paddingBottom: 10 },
    textStyle: baseTextStyle,
    maxTextLines: 1,
  },
  isStacked: true,
};

const CColumnChart = ({
  data,
  colors,
  vTitle,
  hTitle,
  legendDimensionValues,
  multiDimensional,
  chartId,
  dimensions,
}) => {
  const [tooltipData, setTooltipData] = useState(null);
  const ref = useRef(null);
  const mousePosition = useRef({ x: 0, y: 0, globalX: 0, globalY: 0 });
  const { t } = useTranslation();

  const onMouseMove = useCallback(
    (e) => {
      const chartRect = ref.current.getBoundingClientRect();
      const x = e.clientX - chartRect.left;
      const y = e.clientY - chartRect.top;
      mousePosition.current = { x, y, globalX: e.clientX, globalY: e.clientY };
    },
    [ref]
  );

  const onBodyClick = useCallback(() => {
    setTooltipData(null);
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("click", onBodyClick);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("click", onBodyClick);
      }
    };
  }, [ref, onMouseMove, onBodyClick]);

  const onSelect = useCallback(
    ({ wrapper }) => {
      const { row, column } = wrapper.getChart().getSelection()[0];
      const item = data[row];
      const axesData = [{ axis: dimensions[0].value, value: item.dimensionValue, label: translateGroupValue(t, dimensions[0], item.label) }];
      if (multiDimensional) {
        axesData.push({ axis: dimensions[1].value, value: legendDimensionValues[column - 1].value, label: translateGroupValue(t, dimensions[1], legendDimensionValues[column - 1].label) });
      }
      const left = mousePosition.current.globalX <= 260 ? mousePosition.current.x + 300 : mousePosition.current.x;
      setTooltipData({
        axesData,
        left,
        top: mousePosition.current.y,
      });
    },
    [data, dimensions, legendDimensionValues]
  );

  const maxValue = useMemo(() => {
    return Math.max(
      ...data.map((item) => item.values.reduce((acc, value) => acc + value, 0))
    );
  }, [data]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      hAxis: {
        ...baseOptions.hAxis,
        title: hTitle,
        viewWindow: {
          min: 0,
          max: maxValue,
        },
      },
      colors: colors,
      vAxis: {
        ...baseOptions.vAxis,
        title: vTitle,
      },
    }),
    [vTitle, hTitle, colors]
  );

  const headerRow = useMemo(() => {
    console.log(legendDimensionValues);
    return [vTitle, ...legendDimensionValues.flat()];
  }, [vTitle, legendDimensionValues]);

  const columnsData = useMemo(
    () => [
      headerRow,
      ...data.map((item, index) => [item.label, ...item.values.flat()]),
    ],
    [data]
  );

  console.log(columnsData);

  return (
    <div className="column-chart relative" ref={ref}>
      {tooltipData && (
        <CChartPopup
          axesData={tooltipData?.axesData}
          left={tooltipData?.left}
          top={tooltipData?.top}
        />
      )}
      <Chart
        key={chartId}
        chartType="BarChart"
        data={columnsData}
        options={options}
        width="100%"
        loader={<div></div>}
        chartEvents={[
          {
            eventName: "select",
            callback: onSelect,
          },
        ]}
      />
    </div>
  );
};

export default CColumnChart;

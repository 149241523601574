{ Link } = require 'react-router'
Connect = require '../helpers/Connect'
Header = require '../pages/parts/Header'
Menu = require('../pages/parts/Menu')
CReportFastFilter = require '../cmp/results/CReportFastFilter'
CBreadcrumb = require '../cmp/results/CBreadcrumb'
CResult = require '../cmp/results/CResult'
{ API } = require '../redux/Store'
Utils = require '../helpers/Utils'

Const = require '../helpers/Const'
Moment = require 'moment'



module.exports = Connect createReactClass(
  displayName: "ReportResults"

  getInitialState: ()->
    page: 0
    version: -1
    lock: true
    height: null

  componentDidUpdate: ()->
    window.rendered = !!@props.result?.results

  componentDidMount: ()->
    docoument.body.style.overflow = "auto"
    document.querySelector(".wrapper").classList.remove("wrapper")
    if !@props.searchForm
      @props.router.push('/search')
    else
      if !@props.params.filterId
        @props.search(0)

  generateBreadcrumb: (property)->
    if @props.result.headings?.length > 0
      result = []
      @props.result.headings.filter (name, i)=>
        if name.indexOf(".") == -1
          b = property[name]
        else
          b = property[name.split(".")[0]]?[name.split(".")[1]]

        if b
          if name == "externalModifiedAt"
            b = Moment(b).format("YYYY/MM/DD hh:mm")
          result.push (@props.searchFieldsInfo[name]?.headingFormat || "{{value}}").replace("{{value}}", b)

      result.join(";;;")


  applyFilter: (state)->
    if state.savedFilters?.filters?.length > 0 && state.params.filterId && (state.searchForm.filterId != state.params.filterId * 1)
      form = (state.savedFilters.filters.filter (f)=> f.filterId == state.params.filterId * 1)[0]
      if form
        @props.setForm(form)
        @props.search(0)
        return true
      else
        @props.router.push('/search')
    false

  componentWillReceiveProps: (newProps)->
    if !@applyFilter(newProps)
      if newProps.result?.version != @state.version
        @setState page: newProps.page, lock: false, version: newProps.result?.version, height: if newProps.page == 1 then 0 else @state.height

  results: ()->
    if @props.result?.results
      @props.result?.results
    else
      []

  render: ()->
    React.createElement("div", {"className": ""},
      React.createElement("div", {"className": ""},
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": "container"},
            React.createElement("div", {"className": ""},
              React.createElement("div", null,
                React.createElement(CReportFastFilter, null)
              ),
              React.createElement("div", {"className": "search-results"},
                (if @props.searchForm
                  lastBc = null
                  @results().map (property, i)=>
                    React.createElement("span", {"key": (property.id)},
                      (bc = @generateBreadcrumb(property)
                      if lastBc != bc
                        lastBc = bc
                        React.createElement(CBreadcrumb, {"path": (bc)})
                      ),
                      React.createElement(CResult, {"property": (property)})
                    )
                )
              )
            )
          )
        )
      )
    )
) ,{
  state: (state)->
    searchForm: state.searchForm.form
    result: state.searchForm?.result
    page: state.searchForm?.info.page
    client: state.session?.user?.client
    savedFilters: state.savedFilters
    searchFieldsInfo: state.app.searchFieldsInfo
    loading: state.loader.loading
  dispatch: (dispatch)->
    setForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form)
    search: (page) -> dispatch(type: 'SEARCH_ALL', page: page)
    setWatches: (watches) -> dispatch(type: 'SET_WATCHES', watches: watches)
    login: (session)-> dispatch(type: 'LOGIN', payload: session)
}

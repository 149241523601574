import CChartRenderer from "../charts/CChartRenderer";
import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import CFilterDropdown from "../filters/CFilterDropdown";
import {
  AGGREGATE_BY_ENUM,
  CHART_TYPES,
  processChartData,
  NONE_VALUE,
} from "../charts/ChartUtils";
import { API } from "../../redux/Store";
import { useDeepCompareEffect } from "use-deep-compare";
import { useTranslation } from "react-i18next";
import CThreeDots from "../CThreeDots";
import { debounce } from "throttle-debounce";
import { Trans } from "react-i18next";
import { mapGroupToTranslationKey } from "../charts/ChartUtils";

const CResultChartItem = ({
  id,
  isFirst,
  isLast,
  type,
  onTypeChange,
  aggregateValue,
  onAggregateValueChange,
  groups,
  groupBy,
  form,
  setGroupBy,
  defaultIsOpen,
  onDelete,
  onMoveToTop,
  onMoveToBottom,
  onMoveUp,
  onMoveDown,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [chart, setChart] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef(null);
  const { t, i18n } = useTranslation();

  const groupBySorted = useMemo(() => {
    return groupBy.filter((value) => value !== NONE_VALUE).sort();
  }, [groupBy]);

  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.closest(".menu-dropdown")) {
        setIsDropdownOpen(false);
      }
    };
    document.body.addEventListener("click", handleClick);
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);

  const startLoading = useCallback(() => {
    setIsLoading(true);
    if (contentRef.current) {
      contentRef.current.style.height = `${contentRef.current.offsetHeight}px`;
    }
  }, [setIsLoading, contentRef]);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
    if (contentRef.current) {
      contentRef.current.style.height = "auto";
    }
  }, [setIsLoading, contentRef]);

  const fetchData = useCallback(
    (searchForm, groups) => {
      API.getPropertiesCharts(groups, { ...searchForm }, (result) => {
        const processedChart = processChartData({
          chart: result,
          groupBy: groups,
        });
        setChart(processedChart);
        stopLoading();
      });
    },
    [setChart, stopLoading]
  );

  const debouncedFetchData = useMemo(
    () => debounce(300, fetchData),
    [fetchData]
  );

  useDeepCompareEffect(() => {
    if (!groupBySorted.length) {
      return;
    }
    startLoading();
    debouncedFetchData(form, groupBySorted);
  }, [debouncedFetchData, form, groupBySorted, startLoading]);

  const onCheckAggregatedBy = useCallback(
    (value) => () => {
      if (type === CHART_TYPES.PIE && value.length > 1 && groupBy[1]) {
        setNthGroupBy(1, NONE_VALUE);
      }
      onAggregateValueChange(id, value);
    },
    [id, onAggregateValueChange, type, groupBy]
  );

  const onSelectChartType = useCallback(
    (value) => () => {
      if (
        value === CHART_TYPES.PIE &&
        aggregateValue.length > 1 &&
        groupBy[1]
      ) {
        onAggregateValueChange(id, [AGGREGATE_BY_ENUM.PROPERTIES]);
      }
      onTypeChange(id, value);
    },
    [id, onTypeChange, onAggregateValueChange, type, aggregateValue, groupBy]
  );

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
    setIsDropdownOpen(false);
  }, [setIsOpen, isOpen, setIsDropdownOpen]);

  const setNthGroupBy = useCallback(
    (index, value) => {
      setGroupBy(
        id,
        groupBy.map((group, i) => (i === index ? value : group))
      );
    },
    [id, setGroupBy, groupBy]
  );

  const onSelectGroupByFirst = useCallback(
    (_, value) => {
      const changed = value !== groupBy[0];
      if (changed) {
        startLoading();
        setNthGroupBy(0, value);
      }
    },
    [id, startLoading, setNthGroupBy, groupBy]
  );

  const onSelectGroupBySecond = useCallback(
    (_, value) => {
      const newValue = value || null;
      const changed = newValue !== groupBy[1];
      if (changed) {
        if (type === CHART_TYPES.PIE && aggregateValue.length > 1) {
          onAggregateValueChange(id, [AGGREGATE_BY_ENUM.PROPERTIES]);
        }
        startLoading();
        setNthGroupBy(1, newValue);
      }
    },
    [id, type, aggregateValue, startLoading, setNthGroupBy, groupBy]
  );

  const onApply = useCallback(
    (e) => {
      e.preventDefault();
      setIsOpen(false);
    },
    [setIsOpen]
  );

  const onSwitch = useCallback(
    (e) => {
      e.preventDefault();
      if (groupBy.filter((value) => value !== NONE_VALUE).length > 1) {
        setGroupBy(id, [groupBy[1], groupBy[0]]);
      }
    },
    [id, groupBy, setGroupBy, startLoading, stopLoading]
  );

  const groupByFirstOptions = useMemo(
    () =>
      groups.map((group) => ({
        label: t(`charts.${mapGroupToTranslationKey(group)}`, { defaultValue: group }),
        value: group,
        disabled: group === groupBy[1],
      })),
    [groups, groupBy, i18n.resolvedLanguage]
  );

  const groupBySecondOptions = useMemo(
    () =>
      groups.map((group) => ({
        label: t(`charts.${mapGroupToTranslationKey(group)}`, { defaultValue: group }),
        value: group,
        disabled: group === groupBy[0],
      })),
    [groups, groupBy, i18n.resolvedLanguage]
  );

  const title = useMemo(() => {
    const hasSecondGroupBy = groupBy[1] !== NONE_VALUE;
    if (!hasSecondGroupBy) {
      return t(`charts.${mapGroupToTranslationKey(groupBy[0])}`, { defaultValue: groupBy[0] });
    }
    return t("charts.title", {
      groupBy1: t(`charts.${mapGroupToTranslationKey(groupBy[0])}`, { defaultValue: groupBy[0] }),
      groupBy2: t(`charts.${mapGroupToTranslationKey(groupBy[1])}`, { defaultValue: groupBy[1] }),
    });
  }, [groupBy, t, i18n.resolvedLanguage]);

  const onDeleteClick = useCallback(() => {
    onDelete(id);
    setIsDropdownOpen(false);
  }, [id, onDelete, setIsDropdownOpen]);

  const onMoveToTopClick = useCallback(() => {
    onMoveToTop(id);
    setIsDropdownOpen(false);
  }, [id, onMoveToTop, setIsDropdownOpen]);

  const onMoveUpClick = useCallback(() => {
    onMoveUp(id);
    setIsDropdownOpen(false);
  }, [id, onMoveUp, setIsDropdownOpen]);

  const onMoveDownClick = useCallback(() => {
    onMoveDown(id);
    setIsDropdownOpen(false);
  }, [id, onMoveDown, setIsDropdownOpen]);

  const onMoveToBottomClick = useCallback(() => {
    onMoveToBottom(id);
    setIsDropdownOpen(false);
  }, [id, onMoveToBottom, setIsDropdownOpen]);

  const chartRendererProps = useDeepCompareMemo(() => {
    return {
      id,
      type,
      dimensions: groupBy.filter((value) => value !== NONE_VALUE),
      chart,
      aggregateBy: aggregateValue,
    };
  }, [id, type, groupBy, chart, aggregateValue]);

  return (
    <div className="charts-results-item">
      <div className="chart-header">
        <div className="chart-header-title-container">
          <div className="chart-header-toggle" onClick={toggleOpen}>
            <i className={`fa fa-angle-${isOpen ? "up" : "down"}`}></i>
            <span className="chart-title">{title}</span>
          </div>
          <div className={"box-dropdown nav-action text-right"}>
            <CThreeDots
              onClick={(e) => {
                e.preventDefault();
                setIsDropdownOpen(!isDropdownOpen);
              }}
            />
            {isDropdownOpen && (
              <div className="menu-dropdown">
                <a className="btn" onClick={toggleOpen}>
                  {isOpen ? t("charts.doneEditing") : t("charts.edit")}
                </a>
                <a className="btn" onClick={onDeleteClick}>
                  {t("charts.menu.delete")}
                </a>
                <a
                  className={`btn ${isFirst ? "disabled" : ""}`}
                  disabled={isFirst}
                  onClick={onMoveToTopClick}
                >
                  {t("charts.menu.moveTop")}
                </a>
                <a
                  className={`btn ${isFirst ? "disabled" : ""}`}
                  disabled={isFirst}
                  onClick={onMoveUpClick}
                >
                  {t("charts.menu.moveUp")}
                </a>
                <a
                  className={`btn ${isLast ? "disabled" : ""}`}
                  disabled={isLast}
                  onClick={onMoveDownClick}
                >
                  {t("charts.menu.moveDown")}
                </a>
                <a
                  className={`btn ${isLast ? "disabled" : ""}`}
                  disabled={isLast}
                  onClick={onMoveToBottomClick}
                >
                  {t("charts.menu.moveBottom")}
                </a>
              </div>
            )}
          </div>
        </div>
        {isOpen && (
          <form className="form form-small chart-header-controls">
            <div className="chart-header-controls-row">
              <div className="chart-header-controls-column">
                <div className="chart-header-controls-column-item">
                  <Trans
                    i18nKey="charts.breakOutBy"
                    components={{
                      label: <span className="chart-header-control-label" />,
                      control: (
                        <CFilterDropdown
                          name="groupByFirst"
                          id="groupByFirst"
                          onChange={onSelectGroupByFirst}
                          value={groupBy[0]}
                          nullable={false}
                          options={groupByFirstOptions}
                        />
                      ),
                    }}
                  />
                </div>
                <div className="chart-header-controls-column-item">
                  <Trans
                    i18nKey="charts.andBy"
                    components={{
                      label: <span className="chart-header-control-label" />,
                      control: (
                        <CFilterDropdown
                          name="groupBySecond"
                          id="groupBySecond"
                          onChange={onSelectGroupBySecond}
                          value={groupBy[1] || NONE_VALUE}
                          nullable={false}
                          options={[
                            {
                              label: t("filter.dropdown.none"),
                              value: NONE_VALUE,
                            },
                            ...groupBySecondOptions,
                          ]}
                        />
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="chart-header-controls-switch flex-center">
                <button className="btn btn-text" onClick={onSwitch}>
                  <i className="fa fa-arrows-v" aria-hidden="true"></i>
                  <span>{t("charts.switch")}</span>
                </button>
              </div>
            </div>
            <div className="chart-header-controls-column">
              <div className="chart-header-controls-column-item flex-wrap">
                <span className="chart-header-control-label">
                  {t("charts.aggregateBy")}
                </span>
                <div className="box-control radio-group">
                  <div className="box-checkbox">
                    <input
                      id={`aggregateByProperties-${id}`}
                      type="radio"
                      checked={
                        aggregateValue.length === 1 &&
                        aggregateValue[0] === AGGREGATE_BY_ENUM.PROPERTIES
                      }
                      onChange={onCheckAggregatedBy([
                        AGGREGATE_BY_ENUM.PROPERTIES,
                      ])}
                    />
                    <label className="capitalize" htmlFor={`aggregateByProperties-${id}`}>
                      {t("charts.properties")}
                    </label>
                  </div>
                  <div className="box-checkbox">
                    <input
                      id={`aggregateByRooms-${id}`}
                      type="radio"
                      checked={
                        aggregateValue.length === 1 &&
                        aggregateValue[0] === AGGREGATE_BY_ENUM.ROOMS
                      }
                      onChange={onCheckAggregatedBy([AGGREGATE_BY_ENUM.ROOMS])}
                    />
                    <label className="capitalize" htmlFor={`aggregateByRooms-${id}`}>
                      {t("charts.rooms")}
                    </label>
                  </div>
                  <div className="box-checkbox">
                    <input
                      id={`aggregateByBoth-${id}`}
                      type="radio"
                      checked={
                        aggregateValue.includes(AGGREGATE_BY_ENUM.PROPERTIES) &&
                        aggregateValue.includes(AGGREGATE_BY_ENUM.ROOMS)
                      }
                      onChange={onCheckAggregatedBy([
                        AGGREGATE_BY_ENUM.PROPERTIES,
                        AGGREGATE_BY_ENUM.ROOMS,
                      ])}
                    />
                    <label className="capitalize" htmlFor={`aggregateByBoth-${id}`}>
                      {t("charts.both")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="chart-header-controls-column-item flex-wrap">
                <span className="chart-header-control-label">
                  {t("charts.chartType")}
                </span>
                <div className="box-control radio-group">
                  <div className="box-checkbox">
                    <input
                      id={`chartTypeTable-${id}`}
                      type="radio"
                      checked={type === CHART_TYPES.TABLE}
                      onChange={onSelectChartType(CHART_TYPES.TABLE)}
                    />
                    <label htmlFor={`chartTypeTable-${id}`}>
                      {t("charts.table")}
                    </label>
                  </div>
                  <div className="box-checkbox">
                    <input
                      id={`chartTypeColumn-${id}`}
                      type="radio"
                      checked={type === CHART_TYPES.COLUMN}
                      onChange={onSelectChartType(CHART_TYPES.COLUMN)}
                    />
                    <label htmlFor={`chartTypeColumn-${id}`}>
                      {t("charts.bar")}
                    </label>
                  </div>
                  <div className="box-checkbox">
                    <input
                      id={`chartTypePie-${id}`}
                      type="radio"
                      checked={type === CHART_TYPES.PIE}
                      onChange={onSelectChartType(CHART_TYPES.PIE)}
                    />
                    <label htmlFor={`chartTypePie-${id}`}>
                      {t("charts.pie")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart-header-controls-column flex-end">
              <button className="btn btn-outline" onClick={onApply}>
                {t("charts.done")}
              </button>
            </div>
          </form>
        )}
      </div>
      <div className="charts-results-content" ref={contentRef}>
        {isLoading && <div className="loader loader-white-bg" />}
        {!isLoading && chart && <CChartRenderer {...chartRendererProps} />}
      </div>
    </div>
  );
};

export default React.memo(CResultChartItem);

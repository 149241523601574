import React from "react";
import { SlickgridReact } from "slickgrid-react";
import "../../assets/style/slickgrid.scss";

const CSlickgridWrapper = ({ data, columns, onRowCountChanged }) => {
  const colDefs = columns.map((column) => ({
    id: column,
    name: column,
    field: column,
    minWidth: 100,
    sortable: true,
    filterable: true,
  }));

  const gridOptions = {
    enableFiltering: true,
    enableAutoTooltip: true,
    enableSorting: true,
    enableColumnReordering: true,
    enableGridMenu: false,
    enableHeaderMenu: false,
    tristateMultiColumnSort: true,
    gridWidth: "100%",
    gridHeight: 500,
    showCustomFooter: false,
  };

  return (
    <SlickgridReact
      gridId="myGrid"
      columnDefinitions={colDefs}
      dataset={data.map((row, index) => ({
        ...row,
        id: index,
      }))}
      gridOptions={gridOptions}
      onRowCountChanged={({ detail }) => {
        onRowCountChanged(detail.args.current, detail.args.itemCount)
      }}
    />
  );
};

export default React.memo(CSlickgridWrapper);

import camelCase from "lodash.camelcase";

export const AGGREGATE_BY_ENUM = {
  PROPERTIES: "ITEMS",
  ROOMS: "ROOMS",
  BOTH: "BOTH",
};

export const NONE_VALUE = "NONE";

export const CHART_TYPES = {
  PIE: "PIE",
  COLUMN: "COLUMN",
  TABLE: "TABLE",
};

export const OTHER_TRANSLATION_KEY = "charts.others";
export const SMALLEST_COLOR = "#8D3040";
export const SINGLE_COLOR = "#679DB3";

export const CHART_COLORS = [
  "#F0CC88",
  "#679DB3",
  "#72A699",
  "#DDCFBD",
  "#695541",
  "#939598",
  "#004F74",
  "#83A75F",
  "#926A80",
  "#6A715A",
  "#AE673E",
  "#D1D3D4",
  "#DBA84E",
  "#D29D91",
  "#DEC531",
  "#c85b6e",
  "#f8e6c4",
  "#89b3c4",
  "#91bab0",
  "#e3d7c8",
  "#a4876a",
  "#8f9093",
  "#37b7ff",
  "#9cb980",
  "#a88799",
  "#8d957a",
  "#c98b67",
  "#dadbdc",
  "#e1b66a",
  "#dcb2a9",
  "#e5d15d",
  "#d98e9b",
  "#fcf3e2",
  "#a0c2cf",
  "#a6c7bf",
  "#eee7df",
  "#c5b19f",
  "#a9aaac",
  "#6ac9ff",
  "#adc596",
  "#bda5b2",
  "#a5ac96",
  "#d6a88d",
  "#e3e3e4",
  "#eacb94",
  "#e6c8c1",
  "#ecdd89",
  "#d98afb",
  "#c2d8e0",
  "#c5dbd5",
  "#f4efea",
  "#d1c3b4",
  "#c3c4c5",
  "#9ddbff",
  "#c7d7b7",
  "#daccd4",
  "#c6cabc",
  "#e8cebf",
  "#f4f4f5",
  "#f2e0bf",
  "#f0ddd9",
  "#f5eec4",
];

export const getColorByDimension = (axis, aggregateByForLegend) => {
  const sorted = axis.totals.sort(
    (a, b) => b[aggregateByForLegend] - a[aggregateByForLegend]
  );
  const colors = {};
  sorted.forEach((item, index) => {
    const isLast = index === sorted.length - 1;
    const color = isLast
      ? SMALLEST_COLOR
      : CHART_COLORS[index % CHART_COLORS.length];
    colors[item.group] = color;
  });
  return colors;
};

export const takeTopN = ({ t, chart, axes, shouldSortByRooms, aggregateBy }) => {
  const processedAxes = {};

  chart.axes.forEach((axis) => {
    const axisOptions = axes.find((item) => item.axis === axis.axis);
    if (!axisOptions) {
      return axis;
    }

    const groups = axis.totals.map((item) => item.group);
    const sortedTotals = axis.totals.sort((a, b) => {
      const propertiesDiff =
        b[AGGREGATE_BY_ENUM.PROPERTIES] - a[AGGREGATE_BY_ENUM.PROPERTIES];
      const roomsDiff = b[AGGREGATE_BY_ENUM.ROOMS] - a[AGGREGATE_BY_ENUM.ROOMS];
      return propertiesDiff || roomsDiff;
    });
    const [totals, others] = [
      sortedTotals.slice(0, axisOptions.max),
      sortedTotals.slice(axisOptions.max),
    ];
    const othersSum = others.reduce(
      (acc, item) => {
        acc[AGGREGATE_BY_ENUM.PROPERTIES] += item[AGGREGATE_BY_ENUM.PROPERTIES];
        acc[AGGREGATE_BY_ENUM.ROOMS] += item[AGGREGATE_BY_ENUM.ROOMS];
        return acc;
      },
      {
        [AGGREGATE_BY_ENUM.PROPERTIES]: 0,
        [AGGREGATE_BY_ENUM.ROOMS]: 0,
      }
    );

    if (others.length) {
      totals.push({
        group: "Other",
        label: t(OTHER_TRANSLATION_KEY),
        [AGGREGATE_BY_ENUM.PROPERTIES]: othersSum[AGGREGATE_BY_ENUM.PROPERTIES],
        [AGGREGATE_BY_ENUM.ROOMS]: othersSum[AGGREGATE_BY_ENUM.ROOMS],
      });
      groups.push("Other");
    }
    const axisGroups = [...groups].filter((item) =>
      totals.find((total) => total.group === item)
    ).map((item) => {
      const total = totals.find((total) => total.group === item);
      return {
        value: total.group,
        label: total.label,
      };
    });
    processedAxes[axis.axis] = {
      ...axis,
      totals,
      groups: axisGroups
    };
    if (axis.sortedByCount && shouldSortByRooms && aggregateBy === AGGREGATE_BY_ENUM.ROOMS) {
      processedAxes[axis.axis].groups.sort((a, b) => {
        const aTotal = totals.find((total) => total.group === a.value);
        const bTotal = totals.find((total) => total.group === b.value);
        return (
          bTotal[AGGREGATE_BY_ENUM.ROOMS] - aTotal[AGGREGATE_BY_ENUM.ROOMS]
        );
      });
    }
    // processedAxes[axis.axis].labels = processedAxes[axis.axis].groups.map((item) => item.label); 
    // processedAxes[axis.axis].groups = processedAxes[axis.axis].groups.map((item) => item.value);
  });

  const axesKeys = Object.keys(processedAxes);
  const processedCells = chart.cells.reduce((acc, currentCell) => {
    const cell = { ...currentCell };
    axesKeys.forEach((axisKey) => {
      const cellAxisValue = cell[axisKey].value;
      const axisTotals = processedAxes[axisKey].totals;
      const found = axisTotals.find((total) => total.group === cellAxisValue);
      if (!found) {
        cell[axisKey] = {
          value: "Other",
          label: t(OTHER_TRANSLATION_KEY),
        };
      }
    });
    let found = false;
    const newCells = acc.map((item) => {
      if (axesKeys.every((axisKey) => item[axisKey].value === cell[axisKey].value)) {
        found = true;
        return {
          ...item,
          values: {
            [AGGREGATE_BY_ENUM.PROPERTIES]:
              item.values[AGGREGATE_BY_ENUM.PROPERTIES] +
              cell.values[AGGREGATE_BY_ENUM.PROPERTIES],
            [AGGREGATE_BY_ENUM.ROOMS]:
              item.values[AGGREGATE_BY_ENUM.ROOMS] +
              cell.values[AGGREGATE_BY_ENUM.ROOMS],
          },
        };
      }
      return item;
    });
    if (!found) {
      return [...acc, cell];
    }
    return newCells;
  }, []);


  // console.log({processedAxes, axes, chart, processedCells: processedCells.map((item) => {
  //   const nextItem = { ...item };
  //   axesKeys.forEach((axisKey) => {
  //     nextItem[axisKey] = item[axisKey].label || "null";
  //   });
  //   return nextItem;
  // }),
  // });
  return {
    ...chart,
    axes: processedAxes,
    cells: processedCells
  };
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const processChartData = ({ chart, groupBy }) => {
  const { axes, table, cells } = chart;
  const processedAxes = axes.map((axis) => {
    return {
      axis: axis.axis,
      overlapping: axis.overlapping,
      sortedByCount: axis.sortedByCount,
      totals: axis.cells.map((cell) => ({
        group: cell.group[0] + "",
        label: cell.labels[0] + "",
        [AGGREGATE_BY_ENUM.PROPERTIES]: cell.count,
        [AGGREGATE_BY_ENUM.ROOMS]: cell.rooms,
      })),
    };
  });
  const processedCells = cells.map((item) => {
    const obj = {
      values: {
        [AGGREGATE_BY_ENUM.PROPERTIES]: item.count,
        [AGGREGATE_BY_ENUM.ROOMS]: item.rooms,
      },
    };
    item.group.forEach((group, i) => {
      obj[groupBy[i]] = {
        value: group + "",
        label: item.labels[i],
      }
    });
    return obj;
  });
  return {
    axes: processedAxes,
    table: {
      [AGGREGATE_BY_ENUM.PROPERTIES]: table.total,
      [AGGREGATE_BY_ENUM.ROOMS]: table.rooms,
    },
    cells: processedCells,
  };
};

export const aggregateDataForTable = ({
  t,
  aggregateBy,
  data,
  dimensions,
  axes,
  table,
  legendDimensionValues,
  legendByDimension,
  otherDimension,
}) => {
  const rowDimension = otherDimension || legendByDimension;
  const rowDimensionValues = axes[rowDimension].groups;
  const isMultiAggregate = aggregateBy.length > 1;

  const getValues = (itemValues) => {
    if (isMultiAggregate) {
      return aggregateBy.map((aggregate) => itemValues?.[aggregate] || 0);
    }
    return [itemValues?.[aggregateBy[0]] || 0];
  };
  const aggregatedData = rowDimensionValues.map((value) => {
    if (dimensions.length === 1) {
      return [
        {
          label: translateGroupValue(t, rowDimension, value.label),
          value: value.value,
        },
        ...getValues(data.find((item) => item[rowDimension].value === value.value)?.values),
      ];
    }
    const values = legendDimensionValues.map((legendValue) => {
      const item = data.find((item) => {
        return (
          item[rowDimension].value === value.value &&
          item[legendByDimension].value === legendValue.value
        );
      });
      return getValues(item?.values);
    });

    const axis = axes[rowDimension];
    const total = axis.totals.find((total) => total.group === value.value);
    values.push(getValues(total));

    return [
      {
        label: translateGroupValue(t, rowDimension, value.label),
        value: value.value,
      },
      ...values.flat(),
    ];
  });

  const totalRow = [
    {
      label: t("charts.total"),
      value: "Total",
    },
  ];
  const axis = axes[legendByDimension];
  if (dimensions.length > 1) {
    legendDimensionValues.forEach((value) => {
      const total = axis.totals.find((total) => total.group === value.value);
      totalRow.push(getValues(total));
    });
  }
  totalRow.push(getValues(table));
  aggregatedData.push(totalRow.flat());

  const columns = [];
  const columnRow = [
    ...legendDimensionValues.map((value) => ({
      type: "number",
      label: translateGroupValue(t, legendByDimension, value.label),
      value: value.value,
      colspan: isMultiAggregate ? 2 : 1,
    })),
    {
      type: "number",
      label: t("charts.total"),
      value: "Total",
      colspan: isMultiAggregate ? 2 : 1,
    },
  ];

  columnRow.unshift({
    type: "string",
    label: "",
    colspan: 1,
  });

  if (dimensions.length > 1) {
    columns.push(columnRow);
  }

  if (isMultiAggregate || dimensions.length === 1) {
    const subColumns = [
      {
        type: "string",
        label: "",
        colspan: 1,
      },
    ];
    if (dimensions.length > 1) {
      subColumns.push(
        ...legendDimensionValues
          .map(() =>
            aggregateBy.map((aggregate) => ({
              type: "number",
              label: aggregateToLabel(t, aggregate),
              colspan: 1,
            }))
          )
          .flat()
      );
    }
    subColumns.push(
      ...aggregateBy.map((aggregate) => ({
        type: "number",
        label: aggregateToLabel(t, aggregate),
        colspan: 1,
      }))
    );
    columns.push(subColumns);
  }
  return {
    isMultiAggregate,
    aggregateBy,
    rows: aggregatedData,
    columns,
  };
};

export const aggregateDataForColumn = ({
  t,
  aggregateValue,
  data,
  dimensions,
  axes,
  legendDimensionValues,
  legendByDimension,
  otherDimension,
}) => {
  const rowDimension = otherDimension || legendByDimension;
  const rowDimensionValues = axes[rowDimension].groups;
  const aggregatedData = rowDimensionValues.map((value) => {
    if (dimensions.length === 1) {
      return {
        label: translateGroupValue(t, rowDimension, value.label),
        dimensionValue: value.value,
        values: [
          data.find((item) => item[rowDimension].value === value.value).values[
            aggregateValue
          ],
        ],
      };
    }
    const values = legendDimensionValues.map((legendValue) => {
      const item = data.find((item) => {
        return (
          item[rowDimension].value === value.value &&
          item[legendByDimension].value === legendValue.value
        );
      });
      return item?.values[aggregateValue] || 0;
    });
    return {
      label: translateGroupValue(t, rowDimension, value.label),
      dimensionValue: value.value,
      values: values,
    };
  });
  return aggregatedData;
};

export const constructUniqueKey = (id, type, ...rest) => {
  return `ID:${id}-${type}-${rest.join("-")}-${Math.random()}`;
};

export const aggregateToLabel = (t, aggregateValue) => {
  if (aggregateValue === AGGREGATE_BY_ENUM.PROPERTIES) {
    return capitalize(t("charts.properties"));
  }
  if (aggregateValue === AGGREGATE_BY_ENUM.ROOMS) {
    return capitalize(t("charts.rooms"));
  }
  return aggregateValue;
};

export const mapAggregateByToAPI = (aggregateBy) => {
  if (aggregateBy.length === 1) {
    return aggregateBy[0];
  }
  return AGGREGATE_BY_ENUM.BOTH;
};

export const mapAggregateByToView = (aggregateBy) => {
  if (aggregateBy === AGGREGATE_BY_ENUM.BOTH) {
    return [AGGREGATE_BY_ENUM.PROPERTIES, AGGREGATE_BY_ENUM.ROOMS];
  }
  return [aggregateBy];
};

export const mapGroupByToView = (groupBy) => {
  if (groupBy.length === 1) {
    return [groupBy[0], NONE_VALUE];
  }
  return groupBy;
};

export const mapGroupByToAPI = (groupBy) => {
  return groupBy.filter((item) => item !== NONE_VALUE);
};

const groupNameMap = {
  CHAIN_SCALE: "chainscale",
  BRAND: "brand",
  COUNTRY: "country",
  STATE: "state",
  MARKET: "market",
  WR1: "worldregion1",
  WR2: "worldregion2",
  FRANCO: "brandcompany",
  DEVOWN: "devown",
  MGT: "management",
  STAGE: "projectstage",
  TERRITORY: "territory",
  PROJECT_TYPE: "projecttype",
};

const groupDataTranslationMap = {
  CHAIN_SCALE: "chainScale",
  WR1: "worldRegion1",
  WR2: "worldRegion2",
  STAGE: "projectStage",
  PROJECT_TYPE: "projectType",
  MGT: "management",
  DEVOWN: "devOwn",
  FRANCO: "brandCompany",
};

const projectTypeMap = {
  "New Construction": "showNewConstruction",
  "Renovation": "showRenovations",
  "Conversion": "showConversions",
  "Sale": "showSales",
  "Distressed Asset": "showDistressedAssets",
};

const renovationConversionValues = [
  "Underway",
  "Start Next 6 Months",
  "Early Planning",
];

const axisToSearchFormMap = {
  CHAIN_SCALE: "chainScale",
  BRAND: "brand",
  COUNTRY: "country",
  STATE: "state",
  MARKET: "market",
  WR1: "worldRegion1",
  WR2: "worldRegion2",
  FRANCO: "brandCompany",
  DEVOWN: (value) => {
    if (value === "null") {
      return {
        isDeveloper: false,
      };
    }
    return {
      isDeveloper: true,
      companyId: {
        value: value,
      },
    }
  },
  MGT: (value) => {
    if (value === "null") {
      return {
        isManager: false,
      };
    }
    return {
      isManager: true,
      companyId: {
        value: value,
      },
    }
  },
  STAGE: (value) => {
    if (value === "null") {
      return {
      };
    }
    if (renovationConversionValues.includes(value)) {
      return {
        showRenovations: true,
        showConversions: true,
        projectStage: {
          value: `'${value}'`,
        }
      };
    }
    return {
      showNewConstruction: true,
      projectStage: {
        value: `'${value}'`,
      }
    };
  },
  TERRITORY: "territory",
  PROJECT_TYPE: (value) => {
    if (value === "null") {
      return {
      };
    }
    return {
      [projectTypeMap[value]]: true,
    };
  }
};

export const translateGroupValue = (t, group, value) => {
  return t(`data.${groupDataTranslationMap[group]}.${camelCase(value)}`, {
    ns: "data",
    defaultValue: value,
  });
};

export const mapGroupToTranslationKey = (group) => {
  return groupNameMap[group] || group;
};

export const axesToSearchForm = (axes) => {
  const searchForm = {};
  axes.forEach((axis) => {
    const key = axisToSearchFormMap[axis.axis];
    if (typeof key === "function") {
      Object.assign(searchForm, key(axis.value));
      return;
    }
    searchForm[key] = {
      value: axis.value,
    };
  });
  return searchForm;
};

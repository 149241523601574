import Connect from "../../helpers/Connect";
import { useState, useEffect, useCallback } from "react";
import { API } from "../../redux/Store";
import { axesToSearchForm, mapGroupToTranslationKey, translateGroupValue } from "./ChartUtils";
import { useTranslation } from "react-i18next";
import Const from "../../helpers/Const";
import { formatNumber } from "../../helpers/Utils";
/**
 * @typedef {Object} AxesData
 * @property {string} axis
 * @property {string} value
 */

/**
 * @param {AxesData[]} axesData
 * @param {number} left
 * @param {number} top
 */
const CChartPopup = ({ axesData, left, top, searchForm, initSearchForm, search }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const { t } = useTranslation();
  const [count, setCount] = useState({
    properties: 0,
    rooms: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    API.search(
      {
        ...searchForm,
        ...axesToSearchForm(axesData),
      },
      0,
      10,
      (data) => {
        setIsLoading(false);
        setProperties(
          data.results.map((item) => ({
            id: item.id,
            name: item.name,
            city: item.city,
            country: item.country,
          }))
        );
        setCount({
          properties: data.totalCount,
          rooms: data.roomsCount,
        });
      }
    );
  }, [searchForm, axesData]);

  const goToPropertyList = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    initSearchForm({
      ...searchForm,
      ...axesToSearchForm(axesData),
      activeView: "LIST",
    });
    search();
    console.log(searchForm);
  }, [searchForm, axesData, initSearchForm]);

  if (isLoading) {
    return (
      <div className="charts-popup" style={{ left, top }}>
        <div className="loader loader-white-bg" />
      </div>
    );
  }

  return (
    <div className="charts-popup" style={{ left, top }}>
      <div className="charts-popup-header">
        <div className="charts-popup-header-title">
          {axesData.map((axis) => (
            <div key={axis.axis}>
              {t(`charts.${mapGroupToTranslationKey(axis.axis)}`)}:{" "}
              <span className="bold">{translateGroupValue(t, axis.axis, axis.label || axis.value)}</span>
            </div>
          ))}
        </div>
        <div className="charts-popup-header-count">
          <div>
            {formatNumber(count.properties)} {t("charts.properties")}, {formatNumber(count.rooms)} {t("charts.rooms")}
          </div>
        </div>
      </div>
      {properties.map((property) => (
        <div key={property.id} className="charts-popup-property">
          <a
            href={`/property/${property.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="bold">{property.name}</span>, {property.city},{" "}
            {property.country}
          </a>
        </div>
      ))}
      {properties.length !== count.properties && (
        <div>
          ... and {formatNumber(count.properties - properties.length)} more
        </div>
      )}
      <a className="mt-1 d-block" onClick={goToPropertyList}>
        Go to property list
      </a>
    </div>
  );
};
export default Connect(CChartPopup, {
  dispatch: (dispatch) => ({
    initSearchForm: (form) => dispatch({ type: 'INIT_SEARCH_FORM', defaultValues: form, searchType: Const.SEARCH_TYPE_PROPERTIES, fullReload: true }),
    search: () => dispatch({ type: 'SEARCH', page: 0 })
  }),
  state: (state) => ({
    searchForm: state.searchForm.form,
  }),
});

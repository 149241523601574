{ combineReducers, applyMiddleware, createStore } = require 'redux'
createHashHistory = require 'history/lib/createHashHistory'
{ syncHistoryWithStore, routerReducer, routerMiddleware } = require 'react-router-redux'
{ useRouterHistory, browserHistory } = require 'react-router'
API = require '../helpers/API'
{ ignoreActions, filterActions } = require 'redux-ignore'
{ devToolsEnhancer } = require 'redux-devtools-extension'
{ extend } = require '../helpers/UtilsHelpers'

RApp = require './reducers/RApp'
RMenu = require './reducers/RMenu'
RSession = require('./reducers/RSession').default
RSearchForm = require './reducers/RSearchForm'
RQuickSearchForm = require './reducers/RQuickSearch'
RPopup = require './reducers/RPopup'
RSavedFilters = require './reducers/RSavedFilters'
RRecentView = require './reducers/RRecentView'
RWatches = require './reducers/RWatches'
RNotes = require './reducers/RNotes'
RLoader = require './reducers/RLoader'
RChart = require './reducers/RChart'
RAdmin = require './reducers/RAdmin'
RWS = require './reducers/RWS'
Analytics = require './reducers/RAnalytics'

storeForExport = null
APIForExport = null
AnalyticsForExport = null

rlist =
  app: filterActions RApp((()-> storeForExport), ()-> APIForExport), 
    [
      "SET_SETTINGS", "LOAD_SETTINGS", "SET_ADMIN_TAB", "CHANGE_LOCATION", "CHECK_SERVER_IS_RUNNING", 
      "SET_WARNING_MESSAGE", "SET_EXPORT_IN_PROGRESS", "SET_IMPORT_JOB", 'SET_EXPORT_QUEUE_NUMBER'
    ]
  session: filterActions RSession((()-> storeForExport), (()-> APIForExport), (()-> browserHistory)), 
    ["LOGIN", "LOGOUT", "SET_USER", "ACCEPT_LICENCE", 'UPDATE_USER_DATA']
  searchForm: filterActions RSearchForm((()-> storeForExport), (()-> APIForExport), (()-> AnalyticsForExport)), 
    [
      "SET_MAP_RESULT","SEARCH_MAP","SET_VIEW","SEARCH_ALL","ADD_VALUE_TO_SEARCH_FORM_AND_SEARCH",
      "DELETE_FILTER_ID","REMOVE_ALL_EXCLUDED_PROPERTIES","SET_EXCLUDED_PROPERTIES","EXCLUDE_PROPERTY", 
      "SET_NAME_TO_SEARCH_FORM","NEW_SEARCH","SEARCH","CLEAR_RESULTS","SET_RESULT","EDIT_NOTE_IN_SEARCH_RESULT", "DELETE_NOTE_IN_SEARCH_RESULT","RESET_SORTING",
      "CLEAR_SORTING","INIT_SEARCH_FORM","SET_COUNT_TO_SEARCH_FORM","SET_BLOCKS_ON_SEARCH_FORM",
      "TOGGLE_BLOCK_ON_SEARCH_FORM","ADD_VALUE_TO_SEARCH_FORM","UPDATE_SORTING_TO_SEARCH_FORM",
      "ADD_SORTING_TO_SEARCH_FORM","SET_FILTER_FORM", "SET_FILTER_FORM_AND_SEARCH", "UPDATE_FILTER_FORM","SET_CHANGED","TOGGLE_WATCHING_PROPERTY", 
      "SET_SEARCH_TYPE", "SET_STORAGE_KEY", "RESTORE_PREVIOUS_SEARCH", "SET_MAP_OPTIONS", "SET_ACTIVE_COMPANY", "SET_FORM_WITH_CONSTRAINTS", 
      "SET_SEARCH_FORM_COLUMNS", "SET_LAST_SCROLL_POSITION", "SET_COUNT_LOADING", "SAVE_PREVIOUS_FORM", "SET_SUBSEARCH",
      "SET_UPDATED_SINCE", "SET_DELTA_SINCE", "REMOVE_DELTA_FILTERS", "SET_CHART_TO_SEARCH_FORM", "COUNT"
    ]
  popup: filterActions RPopup, 
    [
      "TOGGLE_POPUP_EDIT_NOTE", "TOGGLE_POPUP_FILTER", "TOGGLE_POPUP_SORT", "TOGGLE_POPUP_PASSWORD_RECOVERY", 
      "TOGGLE_POPUP_WATCH", "TOGGLE_POPUP_CUSTOM", "TOGGLE_POPUP_RESULTS_EXPORT", "TOGGLE_POPUP_TARGET_ACCOUNTS", 
      "TOGGLE_POPUP_MAP_COMPANIES", "TOGGLE_POPUP_COMPANY_CARD", "TOGGLE_POPUP_EDIT_SAVED_SEARCH", "CLOSE_ACTIVE_POPUP", 
      "TOGGLE_POPUP_FILE_RENAME", "TOGGLE_POPUP_PROPERTY_EXPORT", "TOGGLE_POPUP_WP_SSO", "TOGGLE_POPUP_DIGEST_VIEW", "TOGGLE_POPUP_LANGUAGE_ACCESS",
      "TOGGLE_POPUP_MOBILE_FEATURE", "TOGGLE_POPUP_CONFIRM_ACTION", "TOGGLE_POPUP_NOTE_HISTORY"
    ]
  menu: filterActions RMenu, 
    ["TOGGLE_RECENTLY_VIEWED_MENU","TOGGLE_SAVED_SEARCHES_MENU", "TOGGLE_SHARED_SEARCHES_MENU", "TOGGLE_MAIN_MENU", "HIDE_MAIN_MENU","SHOW_MAIN_MENU"]
  savedFilters: filterActions RSavedFilters, 
    ["SET_FILTERS", "SET_FILTERS_SHARED"]
  loader: filterActions RLoader, 
    ["START_LOADING", "STOP_LOADING"]
  recentView: filterActions RRecentView, 
    ["ADD_TO_RECENT","SET_RECENT"]
  watches: filterActions RWatches((()-> storeForExport), (()-> APIForExport), (()-> AnalyticsForExport)), 
    ["DELETE_WATCH", "LOAD_WATCHES", "UPDATE_WATCH", "SET_WATCHES", "CHANGE_PERIOD_FILTER"]
  notes: filterActions RNotes((()-> storeForExport), ()-> APIForExport), 
    ["SET_TAB_FILTER","SET_ACTIVE_TAB","RESET_NOTE_TABS", "LOAD_ACTIONS_TODO", "SET_ACTIONS_TODO"]
  quickSearchForm: filterActions RQuickSearchForm((()-> storeForExport), ()-> APIForExport), 
    [
      "QUICK_SEARCH", "QUICK_SEARCH_SET_RESULT", "SET_QUICK_SEARCH_FORM", "TOGGLE_QUICK_SEARCH_FORM", 
      "RESET_QUICK_SEARCH_FORM", "SET_IS_ACTIVE_QUICK_SEARCH"
    ]
  charts: filterActions RChart((()-> storeForExport), ()-> APIForExport), 
    ["LOAD_CHART_DIMENSIONS", "SET_CHART_DIMENSIONS", "LOAD_FIRST_CHART", "LOAD_CHARTS", "SET_CHARTS"]
  admin: filterActions RAdmin((()-> storeForExport), ()-> APIForExport), 
    [
      "GET_USERS_RECENT_ACTIVITY", "SET_USERS_RECENT_ACTIVITY", "GET_USERS_EXPORT_HISTORY", "SET_USERS_EXPORT_HISTORY", 
      "GET_AUTO_EXPORTS", "SET_AUTO_EXPORTS", "SAVE_AUTO_EXPORT", "DELETE_AUTO_EXPORT", 
      "GET_ANALYTICS", "SET_ANALYTICS", "GET_ANALYTICS_GROUP_SESSIONS", "SET_ANALYTICS_GROUP_SESSIONS", 
      "GET_ANALYTICS_SESSIONS", "SET_ANALYTICS_SESSIONS"
    ]
  ws: filterActions RWS((()-> storeForExport), ()-> APIForExport), 
    ['SUBSCRIBE_USER', 'CHECK_WS_CONNECTION', 'DISCONNECT_WS']

#appHistory = useRouterHistory(createHashHistory)({ queryKey: false })
#routerMiddleware = routerMiddleware(appHistory)


reducers = combineReducers(extend({}, rlist, {routing: routerReducer}))
#middleware = applyMiddleware(routerMiddleware)

if config.LE_ENV == 'production'
  store = createStore(reducers)
else
  store = createStore(reducers, devToolsEnhancer())
  if module.hot
    module.hot.accept rlist, () =>
      store.replaceReducer(reducers)


#syncTranslationWithStore(store)

#history = syncHistoryWithStore(appHistory, store)


exports.store = storeForExport = store
exports.history = browserHistory
exports.API = APIForExport = API(store)
exports.Analytics = AnalyticsForExport = Analytics(APIForExport)

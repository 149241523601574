import React, { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { formatNumber } from "../../helpers/Utils";
import CChartPopup from "./CChartPopup";
import { translateGroupValue } from "./ChartUtils";
import { useTranslation } from "react-i18next";

const isNumber = (value) => {
  return !isNaN(value) && !isNaN(parseFloat(value));
};

const CTableChart = ({
  columns,
  rows,
  isMultiAggregate,
  noTotals,
  dimensions,
}) => {
  const [tooltipData, setTooltipData] = useState(null);
  const tableRef = useRef(null);
  const { t } = useTranslation();
  const onCellClick = useCallback(
    (rowIndex, columnIndex, value) => (e) => {
      if (tooltipData) {
        setTooltipData(null);
        return;
      }
      if (!isNumber(value)) {
        return;
      }
      const rect = e.target.getBoundingClientRect();
      const tableRect = tableRef.current.getBoundingClientRect();
      const left = rect.left - tableRect.left;
      const top = rect.top - tableRect.top;
      const column = (() => {
        if (dimensions.length === 1) {
          return null;
        }
        if (isMultiAggregate) {
          return columns[0][Math.ceil(columnIndex / 2)];
        }
        return columns[0][columnIndex];
      })();
      const axesData = [
        {
          axis: dimensions[0],
          value: rows[rowIndex][0].value,
          label: translateGroupValue(t, dimensions[0], rows[rowIndex][0].label),
        },
      ];
      if (dimensions.length > 1) {
        axesData.push({
          axis: dimensions[1],
          value: column.value,
          label: translateGroupValue(t, dimensions[1], column.label),
        });
      }
      setTooltipData({
        axesData: axesData.filter((item) => item.value !== "Total"),
        left,
        top,
      });
    },
    [rows, columns, dimensions, isMultiAggregate, tooltipData, t]
  );

  const onBodyClick = useCallback((e) => {
    const target = e.target;
    if (target.closest(".google-visualization-table-td.pointer")) {
      return;
    }
    setTooltipData(null);
  }, []);

  const onScroll = useCallback((e) => {
    setTooltipData(null);
  }, []);

  useEffect(() => {
    document.addEventListener("click", onBodyClick);
    return () => {
      document.removeEventListener("click", onBodyClick);
    };
  }, [onBodyClick]);

  useEffect(() => {
    tableRef.current.addEventListener("scroll", onScroll);
    return () => {
      tableRef.current.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  const tableHeader = useMemo(() => {
    return (
      <thead>
        {columns.map((columnRow, index) => (
          <tr key={`${index}-${columnRow.label}`} className="header-row">
            {columnRow.map((column, index) => (
              <th
                key={`${index}-${column.label}`}
                className="google-visualization-table-th"
                colSpan={column.colspan}
              >
                {column.label}
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  }, [columns]);

  const tableBody = useMemo(() => {
    return (
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr
            key={`${rowIndex}-${row.label}`}
            className={rowIndex % 2 === 1 ? "odd-table-row" : "table-row"}
          >
            {row.map((value, index) => (
              <td
                key={`${index}-${value}`}
                className={`google-visualization-table-td ${
                  isNumber(value) ? "pointer" : ""
                }`}
                onClick={onCellClick(rowIndex, index, value)}
              >
                {isNumber(value) ? formatNumber(value) : value.label}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }, [rows]);

  return (
    <div className={`box-charts relative ${noTotals ? "no-totals" : ""}`}>
      {tooltipData && (
        <CChartPopup
          axesData={tooltipData.axesData}
          left={tooltipData.left}
          top={tooltipData.top}
        />
      )}
      <div className="google-visualization-table table-overflow" ref={tableRef}>
        <table
          className={`google-visualization-table-table ${
            isMultiAggregate ? "has-subheader" : ""
          }`}
        >
          {tableHeader}
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default React.memo(CTableChart);

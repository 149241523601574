import { useMemo, useCallback, useEffect } from "react";
import CColumnChart from "./CColumnChart";
import CPieChart from "./CPieChart";
import {
  CHART_TYPES,
  SINGLE_COLOR,
  AGGREGATE_BY_ENUM,
  takeTopN,
  getColorByDimension,
  aggregateDataForColumn,
  aggregateDataForTable,
  constructUniqueKey,
  aggregateToLabel,
  mapGroupToTranslationKey,
  translateGroupValue,
} from "./ChartUtils";
import CChartLegend from "./CChartLegend";
import CTableChart from "./CTableChart";
import { useTranslation } from "react-i18next";

const CChartRenderer = ({ id, type, dimensions, chart, aggregateBy }) => {
  const { t } = useTranslation();

  const legendByDimension = useMemo(() => {
    if (dimensions.length === 1 || type === CHART_TYPES.PIE) {
      return dimensions[0];
    }
    return dimensions[1];
  }, [dimensions, type]);

  const otherDimension = useMemo(() => {
    return dimensions.find((dimension) => dimension !== legendByDimension);
  }, [dimensions, legendByDimension]);

  const takeTopAxesConfig = useMemo(() => {
    if (type === CHART_TYPES.PIE) {
      return [
        {
          axis: legendByDimension,
          max: 10,
          percentageThreshold: 0.02,
          absoluteThreshold: 10,
        },
        {
          axis: otherDimension,
          max: 5,
          percentageThreshold: 0.02,
          absoluteThreshold: 10,
        },
      ];
    }

    if (type === CHART_TYPES.COLUMN) {
      return [
        {
          axis: legendByDimension,
          max: 10,
          percentageThreshold: 0.02,
          absoluteThreshold: 10,
        },
        {
          axis: otherDimension,
          max: 8,
          percentageThreshold: 0.02,
          absoluteThreshold: 10,
        },
      ];
    }
    return [
      {
        axis: legendByDimension,
        max: 12,
        percentageThreshold: 0.02,
        absoluteThreshold: 10,
      },
      {
        axis: otherDimension,
        max: 10,
        percentageThreshold: 0.02,
        absoluteThreshold: 10,
      },
    ];
  }, [type, dimensions, otherDimension]);

  const { data, axes, table } = useMemo(() => {
    const aggregateValue = aggregateBy.includes(AGGREGATE_BY_ENUM.PROPERTIES)
      ? AGGREGATE_BY_ENUM.PROPERTIES
      : AGGREGATE_BY_ENUM.ROOMS;

    const processedChart = takeTopN({
      t,
      chart,
      aggregateBy: aggregateValue,
      axes: takeTopAxesConfig,
      shouldSortByRooms: type === CHART_TYPES.TABLE,
    });
    return {
      data: processedChart.cells,
      axes: processedChart.axes,
      table: chart.table,
    };
  }, [chart, takeTopAxesConfig, type]);

  const showLegend = useMemo(() => {
    if (type === CHART_TYPES.COLUMN) {
      return dimensions.length > 1;
    }
    return type !== CHART_TYPES.TABLE;
  }, [dimensions, type]);

  const legendDimensionValues = useMemo(() => {
    if (dimensions.length === 1 && type === CHART_TYPES.COLUMN) {
      return [t(`charts.${mapGroupToTranslationKey(legendByDimension)}`, {
        defaultValue: legendByDimension,
      })];
    }
    return axes[legendByDimension].groups;
  }, [type, legendByDimension, axes]);

  const colorsByData = useMemo(() => {
    const axis = axes[legendByDimension];
    return getColorByDimension(axis, aggregateBy[0]);
  }, [axes, aggregateBy, legendByDimension]);

  const legendItems = useMemo(() => {
    return legendDimensionValues.map((value) => ({
      label: value.label,
      color: colorsByData[value.value],
    }));
  }, [colorsByData, legendDimensionValues, t]);

  const renderColumnChart = useCallback(
    (aggregateValue, aggregateCount) => {
      const aggregatedData = aggregateDataForColumn({
        t,
        data,
        dimensions,
        axes,
        legendDimensionValues,
        legendByDimension,
        otherDimension,
        aggregateValue,
      });
      const colors =
        dimensions.length === 1
          ? [SINGLE_COLOR]
          : legendDimensionValues.map((value) => colorsByData[value.value]);
      const key = constructUniqueKey(
        id,
        type,
        ...dimensions,
        aggregateToLabel(t, aggregateValue),
        aggregateCount + ""
      );
      return (
        <CColumnChart
          key={key}
          chartId={key}
          data={aggregatedData}
          colors={colors}
          vTitle={t(`charts.${mapGroupToTranslationKey(dimensions[0])}`, {
            defaultValue: dimensions[0],
          })}
          hTitle={aggregateToLabel(t, aggregateValue)}
          multiDimensional={dimensions.length > 1}
          dimensions={dimensions.map((dimension) => ({
            label: t(`charts.${mapGroupToTranslationKey(dimension)}`, {
              defaultValue: dimension,
            }),
            value: dimension,
          }))}
          legendDimensionValues={legendDimensionValues}
        />
      );
    },
    [
      t,
      data,
      dimensions,
      axes,
      legendDimensionValues,
      otherDimension,
      legendByDimension,
      colorsByData,
    ]
  );

  const renderPieChartByAggregate = useCallback(
    (aggregateValue, aggregateCount) => {
      const pieData = data.map((item) => {
        return {
          label: translateGroupValue(t, dimensions[0], item[dimensions[0]].label),
          value: item[dimensions[0]].value,
          count: item.values[aggregateValue],
        };
      });
      const colors = legendDimensionValues.map((value) => colorsByData[value.value]);
      const key = constructUniqueKey(
        id,
        type,
        ...dimensions,
        aggregateValue,
        aggregateCount + ""
      );
      return (
        <CPieChart
          key={key}
          chartId={key}
          data={pieData}
          colors={colors}
          title={aggregateToLabel(t, aggregateValue)}
          dimensions={dimensions.map((dimension) => ({
            label: t(`charts.${mapGroupToTranslationKey(dimension)}`, {
              defaultValue: dimension,
            }),
            value: dimension,
          }))}
        />
      );
    },
    [t, colorsByData, data, dimensions, legendDimensionValues]
  );

  const renderPieChartByDimensionValues = useCallback(
    (index, dimensionValue, aggregateValue) => {
      const matchingData = data
        .filter((item) => item[dimensions[1]].value === dimensionValue.value)
        .map((item) => {
          return {
            label: item[dimensions[0]].value,
            value: item.values[aggregateValue],
          };
        });
      const pieData = legendDimensionValues.map((value) => {
        const item = matchingData.find((item) => item.label === value.value);
        return {
          label: translateGroupValue(t, dimensions[0], value.label),
          value: value.value,
          count: item ? item.value : 0,
        };
      });
      const colors = legendDimensionValues.map((value) => colorsByData[value.value]);
      const key = constructUniqueKey(
        id,
        type,
        `index-${index}`,
        ...dimensions,
        dimensionValue.value,
        aggregateValue
      );
      return (
        <CPieChart
          key={key}
          chartId={key}
          data={pieData}
          colors={colors}
          title={translateGroupValue(t, dimensions[1], dimensionValue.label)}
          titleDimension={{
            axis: dimensions[1],
            value: dimensionValue.value,
            label: translateGroupValue(t, dimensions[1], dimensionValue.label),
          }}
          dimensions={dimensions.map((dimension) => ({
            label: t(`charts.${mapGroupToTranslationKey(dimension)}`, {
              defaultValue: dimension,
            }),
            value: dimension,
          }))}
        />
      );
    },
    [t, data, dimensions, colorsByData, legendDimensionValues]
  );

  const renderTableChart = useCallback(() => {
    const aggregatedData = aggregateDataForTable({
      t,
      data,
      dimensions,
      axes,
      table,
      legendDimensionValues,
      legendByDimension,
      otherDimension,
      aggregateBy,
    });
    const { rows, columns, isMultiAggregate } = aggregatedData;
    return (
      <CTableChart
        rows={rows}
        columns={columns}
        dimensions={dimensions}
        legendByDimension={legendByDimension}
        otherDimension={otherDimension}
        isMultiAggregate={isMultiAggregate}
        noTotals={dimensions.length === 1}
      />
    );
  }, [
    t,
    data,
    dimensions,
    axes,
    table,
    legendDimensionValues,
    otherDimension,
    legendByDimension,
    aggregateBy,
  ]);

  const charts = useMemo(() => {
    if (type === CHART_TYPES.COLUMN) {
      return aggregateBy.map((aggregate) =>
        renderColumnChart(aggregate, aggregateBy.length)
      );
    }
    if (type === CHART_TYPES.PIE && dimensions.length === 1) {
      return aggregateBy.map((aggregate) =>
        renderPieChartByAggregate(aggregate, aggregateBy.length)
      );
    }
    if (type === CHART_TYPES.PIE && dimensions.length === 2) {
      return axes[otherDimension].groups.map((dimensionValue, index) =>
        renderPieChartByDimensionValues(index, dimensionValue, aggregateBy[0])
      );
    }
    if (type === CHART_TYPES.TABLE) {
      return <div className="charts-results-tables">{renderTableChart()}</div>;
    }
    return null;
  }, [
    renderColumnChart,
    renderPieChartByAggregate,
    renderPieChartByDimensionValues,
    renderTableChart,
    type,
    dimensions,
    otherDimension,
    axes,
  ]);

  return (
    <div className="chart-container">
      {showLegend && (
        <CChartLegend
          items={legendItems.map((item) => ({
            ...item,
            label: translateGroupValue(t, legendByDimension, item.label),
          }))}
        />
      )}
      <div className="chart-container-charts">{charts}</div>
    </div>
  );
};

export default React.memo(CChartRenderer);

const CLegend = ({
    items = []
}) => {
    return <div className="chart-legend">
        {items.map((item, index) => (
            <div className="chart-legend-item" key={`${item.label}-${index}`}>
                <i className="fa fa-circle" style={{ color: item.color }}></i>
                <span className="chart-legend-item-label">{item.label}</span>
            </div>
        ))}
    </div>;
};

export default CLegend;
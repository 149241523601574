import { Chart } from "react-google-charts";
import { useRef, useState, useEffect, memo, useCallback } from "react";
import CChartPopup from "./CChartPopup";

const CPieChart = ({ chartId, data, colors, title, dimensions, titleDimension }) => {
  const [tooltipData, setTooltipData] = useState(null);
  const chartArea = { width: "90%", height: "90%" }
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const mousePosition = useRef({ x: 0, y: 0, globalX: 0, globalY: 0 });
  const options = {
    colors: colors,
    legend: "none",
    chartArea: chartArea,
    tooltip: {
      trigger: "hover",
    }
  };

  const onMouseMove = useCallback((e) => {
    const chartRect = ref.current.getBoundingClientRect();
    const x = e.clientX - chartRect.left;
    const y = e.clientY - chartRect.top;
    mousePosition.current = { x, y, globalX: e.clientX, globalY: e.clientY };
  }, [ref]);

  const onBodyClick = useCallback((e) => {
    if (e.target.closest(".charts-popup")) {
      return;
    }
    setTooltipData(null);
  }, [setTooltipData]);


  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientWidth);
      ref.current.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("click", onBodyClick);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("click", onBodyClick);
      }
    };
  }, [ref, onMouseMove, onBodyClick]);

  const onSelect = useCallback(({ wrapper, ...rest }) => {
    const { row } = wrapper.getChart().getSelection()[0];
    const item = data[row];
    const axesData = [
      { axis: dimensions[0].value, value: item.value, label: item.label },
    ];
    if (titleDimension) {
      axesData.push({ axis: titleDimension.axis, value: titleDimension.value, label: titleDimension.label });
    }

    const left = mousePosition.current.globalX <= 260 ? mousePosition.current.x + 300 : mousePosition.current.x;
    setTooltipData({
      axesData,
      left,
      top: mousePosition.current.y,
    });
  }, [dimensions, titleDimension]);

  const dataWithColumns = [
    ["", ""],
    ...data.map((item) => [item.label, item.count]),
  ];

  return (
    <div className="pie-chart relative" ref={ref}>
      {tooltipData && <CChartPopup axesData={tooltipData?.axesData} left={tooltipData?.left} top={tooltipData?.top} />}
      <Chart
        key={`${chartId}`}
        chartType="PieChart"
        data={dataWithColumns}
        options={options}
        width={"100%"}
        height={height}
        loader={<div></div>}
        chartEvents={[
          {
            eventName: "select",
            callback: onSelect,
          }
        ]}
      />
      <div className="chart-title">{title}</div>
    </div>
  );
};

export default memo(CPieChart);

import CResultChartItem from "./CResultChartItem";
import {
  AGGREGATE_BY_ENUM,
  CHART_TYPES,
  NONE_VALUE,
  mapAggregateByToAPI,
  mapAggregateByToView,
  mapGroupByToAPI,
  mapGroupByToView,
} from "../charts/ChartUtils";
import { useState, useEffect, useCallback, useMemo } from "react";
import { API } from "../../redux/Store";
import Connect from "../../helpers/Connect";
import { useDeepCompareMemo } from "use-deep-compare";
import { CLabel } from "../CLabel";
const DEFAULT_CHART_CONFIG = {
  chartType: CHART_TYPES.TABLE,
  aggregateBy: AGGREGATE_BY_ENUM.PROPERTIES,
  groupBy: ["CHAIN_SCALE"],
  defaultIsOpen: true,
};
import { extend } from '../../helpers/UtilsHelpers';

const CResultCharts = ({
  searchForm,
  setCharts,
  isSavedSearch,
  setChanged,
}) => {
  const charts = useDeepCompareMemo(
    () => searchForm.charts,
    [searchForm.charts]
  );

  useEffect(() => {
    if (!charts) {
      setCharts([{ id: 0, ...DEFAULT_CHART_CONFIG }]);
    }
  }, [charts]);

  const results = useMemo(() => {
    return charts?.map((chart) => {
      return {
        ...chart,
        groupBy: mapGroupByToView(chart.groupBy),
        aggregateBy: mapAggregateByToView(chart.aggregateBy),
      };
    });
  }, [charts]);

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    API.getPropertyChartGroups((data) => {
      setGroups(data);
    });
  }, []);

  const updateCharts = useCallback(
    (setter) => {
      if (isSavedSearch) {
        setChanged(true);
      }
      setCharts(setter);
    },
    [setCharts, isSavedSearch]
  );

  const setGroupBy = useCallback(
    (id, groupBy) => {
      updateCharts((prevCharts) =>
        prevCharts.map((chart) =>
          chart.id === id
            ? { ...chart, groupBy: mapGroupByToAPI(groupBy) }
            : chart
        )
      );
    },
    [updateCharts]
  );

  const setType = useCallback(
    (id, chartType) => {
      updateCharts((prevCharts) =>
        prevCharts.map((chart) =>
          chart.id === id ? { ...chart, chartType } : chart
        )
      );
    },
    [updateCharts]
  );

  const onDeleteChart = useCallback(
    (id) => {
      updateCharts((prevCharts) =>
        prevCharts.filter((chart) => chart.id !== id)
      );
    },
    [updateCharts]
  );

  const onMoveToTopChart = useCallback(
    (id) => {
      updateCharts((prevCharts) => [
        prevCharts.find((chart) => chart.id === id),
        ...prevCharts.filter((chart) => chart.id !== id),
      ]);
    },
    [updateCharts]
  );

  const onMoveToBottomChart = useCallback(
    (id) => {
      updateCharts((prevCharts) => [
        ...prevCharts.filter((chart) => chart.id !== id),
        prevCharts.find((chart) => chart.id === id),
      ]);
    },
    [updateCharts]
  );

  const onMoveUpChart = useCallback(
    (id) => {
      updateCharts((prevCharts) => {
        const index = prevCharts.findIndex((chart) => chart.id === id);
        if (index > 0) {
          const newCharts = [...prevCharts];
          const temp = newCharts[index - 1];
          newCharts[index - 1] = newCharts[index];
          newCharts[index] = temp;
          return newCharts;
        }
        return prevCharts;
      });
    },
    [updateCharts]
  );

  const onAggregateValueChange = useCallback(
    (id, aggregateValue) => {
      updateCharts((prevCharts) =>
        prevCharts.map((chart) =>
          chart.id === id
            ? { ...chart, aggregateBy: mapAggregateByToAPI(aggregateValue) }
            : chart
        )
      );
    },
    [updateCharts]
  );

  const onMoveDownChart = useCallback(
    (id) => {
      updateCharts((prevCharts) => {
        const index = prevCharts.findIndex((chart) => chart.id === id);
        if (index < prevCharts.length - 1 && index >= 0) {
          const newCharts = [...prevCharts];
          const temp = newCharts[index + 1];
          newCharts[index + 1] = newCharts[index];
          newCharts[index] = temp;
          return newCharts;
        }
        return prevCharts;
      });
    },
    [updateCharts]
  );

  const onAddChart = useCallback(() => {
    updateCharts((prevCharts) => [
      ...prevCharts,
      {
        id: prevCharts.length,
        ...DEFAULT_CHART_CONFIG,
      },
    ]);
  }, [updateCharts]);

  const form = useMemo(() => {
    return {
      ...extend(true, {}, searchForm),
      version: undefined,
      charts: undefined,
      changed: undefined,
      isLink: undefined,
    };
  }, [searchForm]);

  if (groups.length === 0) {
    return <div className="loader mt-2" />;
  }

  return (
    <div className="charts-results">
      {results.map((result, index) => (
        <CResultChartItem
          id={result.id}
          isFirst={index === 0}
          isLast={index === results.length - 1}
          key={`result-${result.id}`}
          type={result.chartType}
          aggregateValue={result.aggregateBy}
          groupBy={result.groupBy}
          setGroupBy={setGroupBy}
          onTypeChange={setType}
          groups={groups}
          onDelete={onDeleteChart}
          onMoveToTop={onMoveToTopChart}
          onMoveToBottom={onMoveToBottomChart}
          onMoveUp={onMoveUpChart}
          onMoveDown={onMoveDownChart}
          onAggregateValueChange={onAggregateValueChange}
          form={form}
          defaultIsOpen={result.defaultIsOpen}
        />
      ))}
      <button className="add-chart-button" onClick={onAddChart}>
        <i className="fa fa-plus" />
        <CLabel k="charts.addChart" />
      </button>
    </div>
  );
};

export default Connect(CResultCharts, {
  dispatch: (dispatch) => ({
    setChanged: (changed) => dispatch({ type: "SET_CHANGED", changed }),
    setCharts: (charts, cb) =>
      dispatch({ type: "SET_CHART_TO_SEARCH_FORM", charts: charts, cb: cb }),
  }),
  state: (state) => ({
    searchForm: state.searchForm.form,
  }),
});

{ Link } = require 'react-router'
Connect = require '../helpers/Connect'
Header = require '../pages/parts/Header'
Menu = require('../pages/parts/Menu').default
CFastFilter = require '../cmp/results/CFastFilter'
CBreadcrumb = require '../cmp/results/CBreadcrumb'
CResult = require '../cmp/results/CResult'
CResultMap = require '../cmp/results/CResultMap'
CResultCompanies = require '../cmp/results/CResultCompanies'
CMobileDropdownMenu = require '../cmp/CMobileDropdownMenu'
{ CLabel } = require '../cmp/CLabel'
{ API } = require '../redux/Store'
Utils = require '../helpers/Utils'
UserUtils = require '../helpers/UserUtils'
Const = require '../helpers/Const'
Moment = require 'moment'
An = require '../helpers/Analytics'
{ withTranslation } = require 'react-i18next' 
CResultCharts = require('../cmp/results/CResultCharts').default
PREDEFINED_FILTERS_ID = ['pipeline', 'census', 'updated', 'digest']

module.exports = withTranslation() Connect createReactClass(
  displayName: "Results"
  scrollPosition: 0

  getInitialState: ()->
    page: 0
    version: -1
    lock: true
    height: null
    isNameSaved: true
    filterError: null
    originalName: @props.searchForm?.name
    searchType: @props.searchType
    filterId: null,
    originalForm: @props.searchForm,
    savedSearchLoading: Boolean(@props.params.filterId) && !@props.searchForm.useSavedSearch
    isChartsLoading: false


  componentDidMount: ()->
    document.querySelector(".main-content").addEventListener("scroll", @onScroll)
    document.querySelector(".main-content").scrollTop = @props.lastScrollPosition
    if @props.view == 'LIST'
      if !@props.searchForm
        @props.router.push('/search')
      else
        if !@props.params.filterId && !@props.lastScrollPosition
          @props.search(0)
        if @props.lastScrollPosition
          @setState(
            page: @props.page,
            version: @props.result?.version,
            lock: false
          )
          @props.search(@props.page - 1, true) 
    if @props.view == 'CHARTS' && !@props.params.filterId
      @props.search(0)
    @applyFilter(@props)

  generateBreadcrumb: (property)->
    if @props.result.headings?.length > 0
      result = []
      @props.result.headings.forEach (name, i)=>
        if name.indexOf(".") == -1
          b = if name == "usersWithAccess" then property["usersWithAccessHeading"] else property[name]
        else
          b = property[name.split(".")[0]]?[name.split(".")[1]]

        if b
          if name == "externalModifiedAt"
            b = Moment(b).format("YYYY/MM/DD hh:mm")
          result.push (@props.searchFieldsInfo[name]?.headingFormat || "{{value}}").replace("{{value}}", b)

      result.join(";;;")

  componentWillUnmount: ()->
    @props.clearPreviousSearch()
    @props.setLastScrollPosition(@scrollPosition)
    document.querySelector(".main-content").removeEventListener("scroll", @onScroll)


  onScroll: (event) ->
    if @props.view == "LIST"
      @scrollPosition = document.querySelector(".main-content").scrollTop
      dashboardHeight = document.querySelector(".dashboard").offsetHeight
      mainContentHeight = document.querySelector(".main-content").offsetHeight
      scrollHeight = dashboardHeight - mainContentHeight
      searchPoint = scrollHeight - ((scrollHeight - (@state.height || 0)) * 0.2)
      if (!@props.result || @props.result.count > 0) && !@state.lock &&  @scrollPosition > searchPoint
        @setState page: @state.page, lock: true, height: scrollHeight
        @props.search(@state.page)

  applyFilter: (state)->
    deltaSearch = if Utils.hasFeature(@props.client, 'DeltaSearch') then {
      value: Moment().tz('UTC').subtract(7, 'day').startOf('days').format()
      mode: "CURRENT"
    } else undefined
    if state.params.filterId && state.savedFilters?.all?.length > 0 && (state.searchForm.filterId != state.params.filterId) && !(state.params.filterId in PREDEFINED_FILTERS_ID)
      form = state.savedFilters.all.filter((f)=> f.filterId == state.params.filterId)[0]
      if form
        form.useSavedSearch = form.searchType == state.searchType
        form.deltaSearch = deltaSearch
        @setState savedSearchLoading: false, originalName: form.name, filterId: state.params.filterId, () => 
          @props.setForm(form)
          @props.search(0)
    if state.params.filterId in PREDEFINED_FILTERS_ID && state.searchForm.filterId != state.params.filterId
      form = {}
      form.deltaSearch = deltaSearch
      form.useSavedSearch = true
      form.filterId = state.params.filterId
      form.filterValue = state.params.filterValue
      @setState savedSearchLoading: false, filterId: state.params.filterId, () =>
        @props.setForm(form)
        @props.search(0)
        @setState isChartsLoading: false
    else if !@state.filterId && state.params.filterId && state.searchForm.filterId != state.params.filterId
      filterCallback = (form) =>
        if (!form.isLink)
          form.useSavedSearch = true
        if form.charts
          form.charts = form.charts.map((chart, index) =>
            return {
              ...chart,
              id: index
            }
          )
        @setState originalForm: Object.assign({}, form)
        if state.params.filterValue == 'digest'
          if form.propertyChanges && form.propertyChanges.value
            form.propertyChanges.value.mode = 'DAYS'
            form.propertyChanges.value.daysAgo = 7
          else
            form.propertyChanges = value:
                      enabled: true
                      interestingOnly: true
                      mode: 'DAYS'
                      daysAgo: 7
                      values: 
                        interestingOnly: true
                        updated: true
                        project: 
                          interestingOnly: true
                          updated: true
        form.deltaSearch = deltaSearch
        form.useSavedSearch = Boolean(form.filterId) && !form.isLink
        @setState savedSearchLoading: false, originalName: form.name, filterId: state.params.filterId, () =>
          @props.setForm(form)
          @props.search(0)
          @setState isChartsLoading: false
      @setState isChartsLoading: true
      if /^(\d+)$/.exec(state.params.filterId)
          API.getFilter(state.params.filterId, 
            filterCallback
            () =>
              @props.router.push('/search')
          )
        else
          API.getFilterLink(state.params.filterId, 
            filterCallback
            () =>
              @props.router.push('/search')
          )

    else if state.result?.version != @state.version
      @setState savedSearchLoading: false, page: state.page, lock: false, version: state.result?.version, height: if state.page == 1 then 0 else @state.height

  componentWillReceiveProps: (newProps)->
    if newProps.searchForm.filterId != @state.originalForm.filterId
      @setState originalForm: Object.assign({}, newProps.searchForm)
    # console.log("componentWillReceiveProps applyFilter", @state.savedSearchLoading)
    if @state.savedSearchLoading
      return
    @applyFilter(newProps)

  results: ()->
    if @props.result?.results
      @props.result.results
    else
      []

  onSharedChange: (e) ->
    @setFilterName({target: 
      value: @props.searchForm?.name
      isShared: e.target.checked
      weeklyDigest: @props.searchForm?.weeklyDigest
      weeklyDigestShared: @props.searchForm?.weeklyDigestShared
    }, true, true)

  onWeeklyDigestChange: (e) ->
    @setFilterName({target: 
      value: @props.searchForm?.name
      isShared: @props.searchForm?.isShared
      weeklyDigest: e.target.checked
      weeklyDigestShared: @props.searchForm?.weeklyDigestShared
    }, true, true)

  onWeeklyDigestSharedChange: (e) ->
    @setFilterName({target: 
      value: @props.searchForm?.name
      isShared: @props.searchForm?.isShared
      weeklyDigest: @props.searchForm?.weeklyDigest
      weeklyDigestShared: e.target.checked
    }, true, true)

  setFilterName: (e, isSaved = false, setChanged = true)->
    filterName = e.target.value
    isShared = e.target.isShared
    weeklyDigest = e.target.weeklyDigest
    weeklyDigestShared = e.target.weeklyDigestShared
    @props.setNameToSearchForm filterName, isShared, weeklyDigest, weeklyDigestShared
    if setChanged
      @props.setChanged(isSaved or filterName != @state.originalName or isShared != @props.searchForm?.isShared or @props.searchForm?.weeklyDigest != weeklyDigest or @props.searchForm?.weeklyDigestShared != weeklyDigestShared)
    @setState filterError: null, isNameSaved: isSaved

  isSaveAsNewButtonNeeded:()->
    @props.searchForm?.isLink && !@props.searchForm?.isShared || !@props.searchForm?.filterId || @props.searchForm?.changed

  isSaveButtonNeeded:()->
    @props.params.filterValue != 'digest' && (!@props.searchForm?.isLink || @props.searchForm?.isShared) && @props.searchForm?.filterId && @props.searchForm?.changed

  isDeleteButtonNeeded: ()->
    (!@props.searchForm?.isLink || @props.searchForm?.isShared) && @props.searchForm?.filterId && !(@props.searchForm?.filterId in PREDEFINED_FILTERS_ID) && @props.searchForm.createdById == @props.user?.id

  isEditButtonNeeded: ()->
    @props.searchForm?.createdById && (@props.user?.id == @props.searchForm?.createdById || UserUtils.isContentAdmin(@props.user, @props.isAliasEnabled))

  saveSearchSettings: (createNew)->
    if !@props.searchForm?.name
      @setState filterError: React.createElement(CLabel, {"k": "results.fast.filter.validation.cannot.be.empty"})
      return false

    savedFiltersLength = @props.savedFilters.filters.filter((f)=> f.name == @props.searchForm?.name).length
    if (createNew && savedFiltersLength > 0) || (!createNew && savedFiltersLength == 1 && @props.searchForm?.name != @state.originalName)
      @setState filterError: React.createElement(CLabel, {"k": "results.fast.filter.validation.already.exists"})
      return false

    @setState filterError: null

    searchForm = if !createNew then Object.assign({}, @state.originalForm) else @props.searchForm
    searchForm.name = @props.searchForm?.name
    searchForm.isShared = @props.searchForm?.isShared
    searchForm.weeklyDigest = @props.searchForm?.weeklyDigest
    searchForm.weeklyDigestShared = @props.searchForm?.weeklyDigestShared

    if createNew
      An.search.saveNewSearch()
      delete @props.searchForm.filterId
    else
      An.search.updateSearch()

    API.saveFilter searchForm, (filters)=>
      @props.setFilters(filters)
      API.getFiltersShared (filters) =>
        @props.setFiltersShared(filters)
      
      if createNew
        delete @props.searchForm.filterId
      newId = @props.searchForm.filterId || (filters.filter (f)=> f.name == @props.searchForm?.name)[0].filterId
      if createNew
        @props.router.push("/results/#{newId}")
      @setState isNameSaved: true, originalName: @props.searchForm.name
      @props.toggleEditSavedSearchPopup()
    , ()=>
        onCloseFn?()


  saveFilter: (createNew, togglePopup, onCloseFn)->
    if !@props.searchForm?.name
      @setState filterError: React.createElement(CLabel, {"k": "results.fast.filter.validation.cannot.be.empty"})
      return false

    savedFiltersLength = @props.savedFilters.filters.filter((f)=> f.name == @props.searchForm?.name).length
    if (createNew && savedFiltersLength > 0) || (!createNew && savedFiltersLength == 1 && @props.searchForm?.name != @state.originalName)
      @setState filterError: React.createElement(CLabel, {"k": "results.fast.filter.validation.already.exists"})
      return false

    @setState filterError: null

    if createNew
      An.search.saveNewSearch()
      delete @props.searchForm.filterId
    else
      An.search.updateSearch()

    delete @props.searchForm.isLink
    delete @props.searchForm.linkId

    API.saveFilter @props.searchForm, (filters)=>
      @props.setFilters(filters)
      API.getFiltersShared (filters) =>
        @props.setFiltersShared(filters)
      if createNew
        delete @props.searchForm.filterId
      newId = @props.searchForm.filterId || (filters.filter (f)=> f.name == @props.searchForm?.name)[0].filterId
      if @props.searchForm.createdById && @props.searchForm.createdById != @props.user.id && !@props.searchForm.isShared
          #We are from ContentAdmin 
        @props.router.push("/results")
        @props.setForm({})
        @props.search(0)
      else
        @props.router.push("/results/#{newId}")
      @props.setChanged(false)
      @setState isNameSaved: true, originalName: @props.searchForm.name
      onCloseFn?()
      if togglePopup
        @props.toggleEditSavedSearchPopup()
    , ()=>
        onCloseFn?()

    true

  deleteFilter: ()->
    if @props.searchForm.filterId
      An.search.deleteSearch()
      API.deleteFilter @props.searchForm.filterId, (filters)=>
        API.getFiltersShared (filtersShared) =>
          @props.setFiltersShared(filtersShared)
          @props.setFilters(filters)
          @props.deleteFilter()
          @props.router.push("/results")
    true

  export: ()->
    notAutoDismissed = 0
    if @props.exportInfo?.elements?.length >= 3
      @props.exportInfo.elements.forEach (element, i)->
        if element.isFinished && element.receivers?.length
          API.acceptExport(element.id)
        else
          notAutoDismissed++
    if notAutoDismissed < 3
      @props.toggleResultsExportPopup(@showAlertMessage)
    else
      isAnyAccepted = false
      for element in @props.exportInfo.elements
        if element.isPossibleAutoDismiss? && element.isPossibleAutoDismiss
          API.acceptExport(element.id)
          isAnyAccepted = true
          break
      if !isAnyAccepted
        @showAlertMessage(@props.t("search.export.limit.message"), 40)
      else
        @props.toggleResultsExportPopup(@showAlertMessage)

  onCloseMobileDropdownMenu: ()->
    if !@state.isNameSaved
      @props.setNameToSearchForm @state.originalName
      @props.setChanged(false)


  editSavedSearch: (createNew)->()=>
    title = if !createNew && @props.searchForm?.name then "results.fast.filter.rename.search.name" else "results.fast.filter.save.search.name"
    @setState filterError: null
    @props.toggleEditSavedSearchPopup(()=>
      @saveSearchSettings(createNew)
    , title)

  showAlertMessage: (text, fontSize)->
    alertMessage = @refs.alertMessage
    if !fontSize
      fontSize = 50
    alertMessage.style.fontSize = fontSize + "px"
    @setState loading: true
    alertMessage.innerHTML = text
    alertMessage.style.opacity = 1
    setTimeout(()=>
      alertMessage.style.opacity = 0
      @setState loading: false
    , 1000)

  copyLink: () ->
    if !@props.searchForm.isLink || !@props.searchForm.linkId
      API.createLinkFilter @props.searchForm, (form)=>
        if !@props.searchForm.linkId then @props.searchForm.linkId = form.linkId 
        Utils.copyText(window.location.origin + '/results/' + form.linkId, () => @showAlertMessage(@props.t('search.link.has.been.copied')))
        @props.updateForm(@props.searchForm)
    else
      Utils.copyText(window.location.origin + '/results/' + @props.searchForm.linkId, () => @showAlertMessage(@props.t('search.link.has.been.copied')))

  render: ()->
    isOwn = !@props.searchForm?.createdById || @props.searchForm?.createdById == @props.user?.id
    isSavedSearch = @props.searchForm?.useSavedSearch
    React.createElement("div", {"className": "wrapper-content"},
      React.createElement(Header, {"backLabel": (@props.route.backLabel), "searchFormName": (if @props.params.filterValue == 'digest' then @props.t("results.fast.filter.searh.name.digest", {name: @props.searchForm?.name}) else @props.searchForm?.name), "title": (@props.t("header.results"))}),
      (if not @props.activeQuickSearch
        React.createElement(CMobileDropdownMenu, { \
          "target": "results",  \
          "onClose": (@onCloseMobileDropdownMenu),  \
          "options": (if @props.searchType != Const.SEARCH_TYPE_COMPANIES then [
            {label: React.createElement(CLabel, {"k": "results.fast.filter.delete"}), test: @isDeleteButtonNeeded, icon:"fa-close", onClick: @deleteFilter}
            {label: React.createElement(CLabel, {"k": "results.fast.filter.save.new.saved.search.save"}), test: @isSaveAsNewButtonNeeded, icon:"fa-save", onClick: @editSavedSearch(true), checkAccess: true}
            {label: React.createElement(CLabel, {"k": "results.fast.filter.save.changes"}), test: @isSaveButtonNeeded, icon:"fa-repeat", onClick: @saveFilter.bind(@, false, false)}
            {label: React.createElement(CLabel, {"k": "results.fast.filter.export"}), icon:"fa-clipboard", onClick: @export}
            {label: React.createElement(CLabel, {"k": "property.copy.deep.link"}), icon:"fa-copy", onClick: @copyLink}
            {label: React.createElement(CLabel, {"k": "results.fast.filter.edit.saved.search"}), icon:"fa-pencil", test: @isEditButtonNeeded, onClick: @editSavedSearch(false)}
          ] else [
            {label: React.createElement(CLabel, {"k": "results.fast.filter.delete"}), test: @isDeleteButtonNeeded, icon:"fa-close", onClick: @deleteFilter}
            {label: React.createElement(CLabel, {"k": "results.fast.filter.save.new.saved.search.save"}), test: @isSaveAsNewButtonNeeded, icon:"fa-save", onClick: @editSavedSearch(true), checkAccess: true}
            {label: React.createElement(CLabel, {"k": "results.fast.filter.save.changes"}), test: @isSaveButtonNeeded, icon:"fa-repeat", onClick: @saveFilter.bind(@, false, false)}
            {label: React.createElement(CLabel, {"k": "property.copy.deep.link"}), icon:"fa-copy", onClick: @copyLink}
            {label: React.createElement(CLabel, {"k": "results.fast.filter.edit.saved.search"}), test: @isEditButtonNeeded, icon:"fa-pencil", onClick: @editSavedSearch(false)}
          ])
        })
      ),
      React.createElement("div", {"className": "main results-page"},
        React.createElement("div", {"className": "main-content show-main-scroll"},
          React.createElement("div", {"className": 'alert-message', "ref": "alertMessage"}),
          React.createElement("div", {"className": "container"},
            React.createElement(Menu, {"className": "hide-mobile"}),
            React.createElement("div", {"className": ("dashboard " + @props.view)},
              React.createElement("div", {"className": "search-filter filter-fixed-on-mobile"},
                React.createElement(CFastFilter, { \
                  "isSaveAsNewButtonNeeded": (@isSaveAsNewButtonNeeded),  \
                  "isSaveButtonNeeded": (@isSaveButtonNeeded),  \
                  "isDeleteButtonNeeded": (@isDeleteButtonNeeded),  \
                  "export": (@export),  \
                  "deleteFilter": (@deleteFilter),  \
                  "setFilterName": (@setFilterName),  \
                  "copyLink": (@copyLink),  \
                  "saveFilter": (@saveFilter),  \
                  "saveSearchSettings": (@saveSearchSettings),  \
                  "filterError": (@state.filterError),  \
                  "isDigest": (@props.params.filterValue == 'digest'),  \
                  "isNameSaved": (@state.isNameSaved),  \
                  "savedSearchLoading": (@state.savedSearchLoading),  \
                  "isSavedSearch": (isSavedSearch)
                })
              ),
              (if @props.view == "LIST"
                React.createElement("div", {"className": "search-results"},
                  (if @props.searchForm && @props.searchType == Const.SEARCH_TYPE_PROPERTIES or !@props.searchType && @results().length > 0
                    lastBc = null
                    @results().map (property, i)=>
                      React.createElement("span", {"key": (property.id)},
                        (bc = @generateBreadcrumb(property)
                        if lastBc != bc
                          lastBc = bc
                          React.createElement(CBreadcrumb, {"path": (bc)})
                        ),
                        React.createElement(CResult, {"property": (property)})
                      )
                  else if @props.searchForm && @props.searchType == Const.SEARCH_TYPE_COMPANIES && @results().length > 0
                    React.createElement(CResultCompanies, {"results": (@results())})
                  ),
                  (if @results().length == 0 && !@props.loading && isSavedSearch && Utils.hasFeature(@props.client, 'DeltaSearch')
                    React.createElement("div", {"className": "search-results-empty"},
                      React.createElement(CLabel, {"k": "results.delta.no.results"})
                    )
                  ),
                  (if @props.loading
                    React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
                  )
                )
              ),
              (if @props.view == "MAP"
                React.createElement(CResultMap, null)
              ),
              (if @props.view == "CHARTS" && Utils.hasFeature(@props.client, 'ChartView') && !@state.savedSearchLoading && !@state.isChartsLoading
                React.createElement(CResultCharts, {"isSavedSearch": (@props.searchForm?.useSavedSearch)})
              )
            )
          )
        )
      )
    )
) ,{
  state: (state)->
    searchForm: state.searchForm.form
    result: state.searchForm?.result
    page: state.searchForm?.info.page
    client: state.session?.user?.client
    user: state.session?.user
    savedFilters: state.savedFilters
    searchFieldsInfo: state.app.searchFieldsInfo
    loading: state.loader.loading
    view: state.searchForm.form?.activeView
    searchType: state.searchForm.form.searchType
    activeQuickSearch: state.quickSearchForm.active
    translation: state.translation
    lastScrollPosition: state.searchForm?.lastScrollPosition
    locationHistory: state.app.locationHistory
    exportInfo: state.app.exportInfo
    isAliasEnabled: state.session?.isAliasEnabled
  dispatch: (dispatch)->
    setForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form)
    updateForm: (form) -> dispatch(type: 'UPDATE_FILTER_FORM', form: form)
    search: (page, flush) -> dispatch(type: 'SEARCH', page: page, target:"Results", flush: flush)
    setWatches: (watches) -> dispatch(type: 'SET_WATCHES', watches: watches)
    setFilters: (filters) -> dispatch(type: 'SET_FILTERS', filters: filters)
    setFiltersShared: (filters) -> dispatch(type: 'SET_FILTERS_SHARED', shared: filters)
    setChanged: (changed) -> dispatch(type: 'SET_CHANGED', changed: changed)
    setNameToSearchForm: (name, isShared, weeklyDigest, weeklyDigestShared) -> dispatch(type: 'SET_NAME_TO_SEARCH_FORM', name: name, isShared: isShared, weeklyDigest: weeklyDigest, weeklyDigestShared: weeklyDigestShared)
    toggleResultsExportPopup: (onDownload) -> dispatch(type: 'TOGGLE_POPUP_RESULTS_EXPORT', data: onDownload: onDownload)
    toggleEditSavedSearchPopup: (onSave, title) -> dispatch(type: 'TOGGLE_POPUP_EDIT_SAVED_SEARCH', data: onSave: onSave, title: title)
    setLastScrollPosition: (scrollPosition) -> dispatch(type: 'SET_LAST_SCROLL_POSITION', payload: scrollPosition)
    deleteFilter: () -> dispatch(type: 'DELETE_FILTER_ID')
    clearPreviousSearch: () => dispatch({ type: "SAVE_PREVIOUS_FORM", payload: null })
}
